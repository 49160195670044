<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-col
    lg="6"
    cols="12"
    class="pa-0"
  >
    <v-form
      ref="form"
      v-model="formValid"
      lazy-validation
      class="default-input"
    >
      <v-text-field
        id="iiotDeployStepThreeDeployName"
        data-cy="iiotDeployStepThreeDeployName"
        v-model="name"
        :rules="[rules.required, rules.deployNameMinLength, rules.deployNameMaxLength,
        rules.namePattern]"
        :label="$t('deployForm.workloadStep3.deployName')"
        requred
        autofocus
        @focus="emitName()"
        @input="emitName"
        @keydown.enter.prevent="emitDeploy"
      />
    </v-form>
    <v-col
      v-if="selectedWorkloadType !== 'vm'"
      class="pa-0 mt-4"
      cols="12"
    >
      <v-card-title
        id="iiotDeployStepThreeWarningMessage"
        data-cy="iiotDeployStepThreeWarningMessage"
        class="pa-0 warning-message"
      >
        <v-icon
          id="iiotDeployStepThreeWarningIcon"
          data-cy="iiotDeployStepThreeWarningIcon"
          large
          color="orange"
        >
          warning
        </v-icon>
        <label>
          {{ $t('deployForm.workloadStep3.workloadUpdateWarningMessage') }}
        </label>
      </v-card-title>
    </v-col>
    <v-col
    cols="12"
    class="pl-0"
    >
      <v-row class="mt-4">
        <v-col
          lg="6"
          cols="12"
        >
          <label
            class="text-uppercase font-weight-bold"
           >
            {{ $t('deployForm.workloadStep3.workloadType') }}
          </label><br>
          <span>{{ selectedWorkloadType }}</span>
        </v-col>
        <v-col
          lg="6"
          cols="12"
        >
          <label
            class="text-uppercase font-weight-bold"
           >
            {{ $t('deployForm.workloadStep3.workloadName') }}
          </label><br>
          <span>{{ workloadName }}</span>
        </v-col>
      </v-row>
      <v-row>
       <v-col
          lg="6"
          cols="12"
       >
          <label
            class="text-uppercase font-weight-bold"
           >
            {{ $t('deployForm.workloadStep3.workloadVersion') }}
           </label><br>
          <span>{{ versionName }}</span>
       </v-col>
        <v-col
          lg="6"
          cols="12"
        >
          <label
            class="text-uppercase font-weight-bold"
          >
          {{ $t('deployForm.workloadStep3.deployExecute') }}
          </label><br>
          <span>{{ selectedNodes }}
            {{ selectedNodes > 1 ? $t('deployForm.workloadStep3.nodes') :
            $t('deployForm.workloadStep3.node') }}
          </span>
       </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import { VALIDATION_REGEX, DEPLOY_NAME } from '@/constants';

export default {
  props: {
    selectedWorkloadType: {
      type: String,
      default: '',
    },
    workloadName: {
      type: String,
      default: '',
    },
    versionName: {
      type: String,
      default: '',
    },
    selectedNodes: {
      type: Number,
      default: 0,
    },
    deployName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      VALIDATION_REGEX,
      DEPLOY_NAME,
      formValid: true,
      name: '',
    };
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('deployForm.workloadStep3.required'),
        // eslint-disable-next-line no-unused-vars
        deployNameMinLength: (value) => this.name.length >= this.DEPLOY_NAME.MIN_LENGTH
        || this.$t('deployForm.workloadStep3.nodeNameMin', { msgLength: this.DEPLOY_NAME.MIN_LENGTH }),
        // eslint-disable-next-line no-unused-vars
        deployNameMaxLength: (value) => this.name.length <= this.DEPLOY_NAME.MAX_LENGTH
        || this.$t('deployForm.workloadStep3.nodeNameMax', { msgLength: this.DEPLOY_NAME.MAX_LENGTH }),
        namePattern: (v) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(v) || this.$t('deployForm.workloadStep3.noControlCharacters'),
      };
    },
  },
  watch: {
    deployName(val) {
      this.name = val;
    },
  },
  mounted() {
    this.name = this.deployName;
  },
  methods: {
    emitName() {
      this.$emit('deploy-name', { deployName: this.name, formValid: this.$refs.form.validate() });
    },
    emitDeploy() {
      this.$emit('deploy', { formValid: this.$refs.form.validate() });
    },
  },
};
</script>

<style scoped>
  .warning-message {
    font-size: 16px;
    white-space:pre-wrap;
    word-break:break-word;
  }
</style>
